import React, { useCallback, useMemo, useState } from "react";
import { useAuth } from "../../../../store/contextAPI/authContext";
import { getLocalStorage, validatePassword } from "../../../../utils/common-function";
import { supabaseClient } from "../../../../services/supabase/supabaseClient";
import { useHistory } from "react-router";
import { ISignupCredentials } from "../../../../store/contextAPI/types";

const useChangePassword = () => {
  const { user, setPasswordContext, handleLogin } = useAuth();
  const history = useHistory();
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [currentPasswordError, setCurrentPasswordError] = useState<string>("");
  const [newPasswordError, setNewPasswordError] = useState<string>("");
  const [newPassword, setNewPassword] = useState({
    value: "",
    error: {
      isValidLength: false,
      hasLetterAndNumber: false,
      hasSpecialCharacters: false
    }
  });

  const handlePasswordChange = useCallback(async () => {
    const finalEmail: string = user.email ? user.email : getLocalStorage("email");
    const { error: signInError } = await supabaseClient.auth.signInWithPassword({
      email: finalEmail,
      password: currentPassword
    });

    if (signInError) {
      setCurrentPasswordError("Current password is incorrect");
      return;
    }
    else{
      setCurrentPasswordError("");
    }

    const { error: updateError } = await supabaseClient.auth.updateUser({ password: newPassword.value });
    if (updateError) {
      setNewPasswordError(updateError.message);
    } else {
      setNewPasswordError("");
      setCurrentPasswordError("");
      const credentials: ISignupCredentials = {
        email: user.email,
        password: newPassword.value
      };
      await handleLogin(credentials);
      history.push("/");
    }
  }, [currentPassword, newPassword, user.email]);

  const isContinueDisable = useMemo((): boolean => {
    if (currentPassword.length === 0) {
      return true;
    }
    const error = validatePassword(newPassword.value);
    return currentPassword.length !== 0 && !(error.isValidLength && error.hasLetterAndNumber && error.hasSpecialCharacters);
  }, [currentPassword, newPassword.value]);

  const onChangeCurrentPassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    value = value.replace(/\s+/g, "");
    if (value.length > 20) {
      value = value.slice(0, 20);
    }
    setCurrentPassword(value);
  }, []);

  const onChangeNewPassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    value = value.replace(/\s+/g, "");
    if (value.length > 20) {
      value = value.slice(0, 20);
    }
    const errorMessageFlages = validatePassword(value);
    setNewPassword({ value, error: errorMessageFlages });
  }, []);

  const onClickContinueHandler = useCallback(async () => {
    setPasswordContext(newPassword.value);
    handlePasswordChange();
  }, [newPassword.value, setPasswordContext]);

  const classForText = useCallback((value: string, error: boolean): string => {
    if (value.length === 0) return "";
    return error ? "" : "text-error";
  }, []);

  return {
    newPassword,
    currentPassword,
    newPasswordError,
    classForText,
    onClickContinueHandler,
    onChangeNewPassword,
    onChangeCurrentPassword,
    isContinueDisable,
    currentPasswordError
  };
};

export default useChangePassword;
