import React, { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import ErrorImage from "../../assets/images/404Image.svg";
import { useErrorBoundary } from "react-error-boundary";
import mixpanel from "../../services/mixpanelService";
import { useHistory } from "react-router-dom";
import { getLocalStorage } from "../../utils/common-function";

const ErrorScreen: React.FC = () => {
  const history = useHistory();
  const { resetBoundary } = useErrorBoundary();

  const goBack = () => {
    resetBoundary();
    const currentUrl = window.location.href;
    if (currentUrl.includes("stashlink") || currentUrl.includes("campaign")) {
      const stashLink = getLocalStorage("stashLink");
      history.replace(stashLink ? stashLink : "/");
    } else {
      history.replace("/");
    }
  };

  useEffect(() => {
    mixpanel.track("404-page-view");
  }, []);

  return (
    <div className="relative bg-soft-black w-screen h-[calc(100vh-124px)] flex flex-col justify-between items-center text-white">
      <div className="relative w-full">
        <IoIosArrowBack className="absolute top-4 left-4 text-gray-400 h-6 w-6 cursor-pointer" onClick={goBack} />
      </div>
      <div className="text-center">
        <h1 className="font-28px text-neutral-50 leading-tight-29.54 capitalize font-extrabold font-primary">Oops</h1>
        <p className="text-base leading-tight text-neutral-50 font-secondary mt-2">Something went wrong</p>
      </div>
      <img src={ErrorImage} alt="error-screen" />
    </div>
  );
};

export default ErrorScreen;
