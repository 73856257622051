import React from "react";
import { useHistory } from "react-router";
import { FaHeart } from "react-icons/fa6";
import MarqueeComponent from "../common/marquee/marqueeComponent";
import { GoalReachedProps } from "./goalreached.types";
import { marqueeTextIteration } from "../../utils/constants";
import confetti from "../../assets/images/Confetti.gif";
import FollowCheck from "./../../assets/images/follow-check.svg";
import { formatCurrencyToUsd } from "../../utils/format";
import { routes } from "../../routes/routes.constant";
import CloseBtn from "./../../assets/images/close-btn.svg";
import Button from "../common/button";
import ProgressBar from "../common/progressBar";
import FollowingCheck from "../../assets/images/following-check.svg";
import { generateCloudinaryImageUrl } from "../../utils/common-function";
import mixpanel from "../../services/mixpanelService";

const GoalReached: React.FC<GoalReachedProps> = (props: GoalReachedProps) => {
  const {
    user,
    campaign,
    onClose,
    isCampaignFunded,
    isUserLoggedIn,
    isUserFollowing,
    followCelebrity,
    changeStatusFollowing,
    stashLinkId
  } = props;
  const history = useHistory();

  return (
    <div
      className="flex items-center justify-center fixed inset-0 bg-gray-800 backdrop-blur-5 bg-opacity-50 z-50 p-6"
      style={{ backgroundImage: `url(${confetti})` }}>
      <div className="bg-soft-black rounded-20 overflow-hidden border-r-4 border-b-4 border-primary-l1">
        <div className="p-3 bg-gradient-to-t from-modal-bottom-bg to-modal-top-bg rounded-b-2xl">
          <div className="relative">
            <button onClick={onClose} className="absolute top-1 right-1 z-10">
              <img src={CloseBtn} alt={CloseBtn} className="w-7" />
            </button>
            <div className="relative rounded-c-14.3 h-[11.012rem] overflow-hidden rounded-bl-38">
              <div
                className="w-full h-full"
                style={{
                  backgroundImage: `url(${generateCloudinaryImageUrl(user?.profilePhoto || "", "600", "352")})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat"
                }}></div>
              <div className="absolute -bottom-[0.5625rem] -left-2 w-c-90 h-c-90 rounded-full bg-gradient-to-t from-[#2e370d] to-[#303a0d] flex justify-center items-center">
                <img
                  src={generateCloudinaryImageUrl(campaign?.imageUrl || "", "143", "143")}
                  alt={campaign?.title}
                  className="rounded-full w-c-72 h-c-72"
                />
              </div>
            </div>
          </div>
          <div className="text-center mt-2 ">
            <h1 className="text-xl font-primary font-extrabold text-primary-l1">{user?.fullName}</h1>
            <p className="text-white text-sm font-secondary font-normal mt-1 leading-tight">reached the fundraising goal for</p>
            <p className="text-lg font-secondary font-bold text-base-white mt-2 mb-[1rem] leading-snug">{campaign?.charity?.name}</p>
            <div className="border-2 border-b-4 border-black rounded-6px overflow-hidden">
              <ProgressBar percentageCompleted={100} />
            </div>
            <span className="font-primary font-semibold text-neutral-50 text-base tracking-tight">
              {formatCurrencyToUsd(campaign?.goalAmount)}
            </span>
            <span role="img" aria-label="party" className="mx-1">
              🎉
            </span>
          </div>
        </div>

        {isCampaignFunded &&
          (isUserLoggedIn ? (
            <>
              {!isUserFollowing && (
                <div className="text-center rounded-b-2xl px-4 py-3 pb-4">
                  <MarqueeComponent text="Be the first to know" iterationsPerScreen={marqueeTextIteration} separatorIcon={FaHeart} />
                  {isUserLoggedIn && isUserFollowing ? (
                    <div className="w-full mt-2 p-4">
                      <button
                        className="w-full px-4 py-2 text-base font-primary tracking-wider font-extrabold rounded-lg text-white bg-grey-600 border border-primary-l1/30 shadow-[0px_4px_0px_#6C801F]"
                        onClick={() => {
                          history.push("/");
                          mixpanel.track("modal-following-button-clicked");
                        }}>
                        Following{" "}
                        <span className="ml-1">
                          <img src={FollowingCheck} alt="Following Check"></img>
                        </span>
                      </button>
                    </div>
                  ) : (
                    <div className="w-full mt-2">
                      <Button
                        isDisabled={false}
                        onClick={() => {
                          followCelebrity();
                          mixpanel.track("modal-follow-button-clicked");
                        }}
                        title={`Follow ${user?.fullName.split(" ")[0]}`}
                      />
                    </div>
                  )}
                </div>
              )}
              {isUserFollowing && changeStatusFollowing && (
                <>
                  <div className="pt-2">
                    <MarqueeComponent text="Be the first to know" iterationsPerScreen={marqueeTextIteration} separatorIcon={FaHeart} />
                  </div>
                  <div className="w-full mt-2 mb-1 p-4">
                    <button
                      className="w-full px-4 py-3 text-lg font-primary tracking-wider font-bold rounded-xl text-grey bg-grey-bg border border-grey"
                      onClick={() => {
                        history.push("/");
                        mixpanel.track("modal-following-button-clicked");
                      }}>
                      Following <img src={FollowCheck} alt={FollowCheck} className="inline" />
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="pt-2">
                <MarqueeComponent text="Be the first to know" iterationsPerScreen={marqueeTextIteration} separatorIcon={FaHeart} />
              </div>
              <div className="w-full rounded-2xl p-4">
                <Button
                  onClick={() => {
                    mixpanel.track("modal-follow-button-clicked");
                    history.push({
                      pathname: routes.onboarding,
                      state: {
                        userName: user?.fullName,
                        followerProfileImage: user?.profilePhoto,
                        param: { isFollow: true, stashLinkId: stashLinkId }
                      }
                    });
                  }}
                  isDisabled={false}
                  title={`Follow ${user?.fullName.split(" ")[0]}`}
                />
              </div>
            </>
          ))}
        {(!props.isUserFollowing || props.changeStatusFollowing) && (
          <p className="text-sm px-12 pb-4 text-base-white/90 text-center font-secondary">
            Join {user?.fullName.split(" ")[0]}’s community and be the first to know when the next play to fund campaign goes live.
          </p>
        )}
      </div>
    </div>
  );
};

export default GoalReached;
