const ButtonStyle = () => {
  //TODO: will update more tailwind css here
  const containerClass = "w-full gap-8.01 pb-1 rounded-lg";
  const baseStyle = "w-full px-4 py-3.5 text-base font-primary tracking-wider font-wght-740 leading-tight-19.2 gap-2 rounded-lg box-border select-none";
  const enabledStyle = baseStyle + " bg-primary-l1 border-2 border-black shadow-[0px_4px_0px_#6C801F] text-black";
  const disabledStyle = baseStyle + " bg-diasabled text-grey";

  return { enabledStyle, disabledStyle, containerClass };
};

export default ButtonStyle;
