import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import Lottie from "react-lottie";
import loadingLottie from "../../assets/lottie/loading.json";

const GameDisable: React.FC = () => {
  const history = useHistory();
  return (
    <div className="flex flex-col items-center justify-center h-[90vh] bg-black text-white relative overflow-hidden">
      <div className="absolute top-0 left-0 w-full bg-black p-4 flex justify-between items-center">
        <IoIosArrowBack className="w-6 h-6 cursor-pointer text-grey absolute left-4 z-10" onClick={() => history.goBack()} />
      </div>
      <div className="flex flex-col items-center justify-center space-y-2 mt-20">
        <div className="w-[200px] h-[125px] mx-auto">
          <Lottie options={{ animationData: loadingLottie }} />
        </div>

        <h1 className="text-4xl font-bold">wow!</h1>
        <p className="text-center max-w-sm text-xl">
          There&apos;s a huge number of people stashing right now. We&apos;re working hard to let you in. Please hold tight!
        </p>
      </div>
    </div>
  );
};

export default GameDisable;
