import { useState, useEffect, useCallback, useRef } from "react";
import { emptyBubble, gameDefaultAmounts, gameType, oneDollar, timeout } from "../../../common/constants";
import { Bubble } from "../types";
import { useMutation } from "@apollo/client";
import { SampleEligibleBrandsData, StartCoinDropData, Stashlink } from "../../../types";
import { COLLECT_COINS, START_COIN_DROP } from "../../../queries";
import { getLocalStorage, setLocalStorage } from "../../../utils/common-function";
import useGameSounds from "./useGameSounds";
import { useHistory, useLocation } from "react-router";
import { GestureHandler } from "../../../utils/gestureHandler";
import scroll from "../../../utils/scroll";
import mixpanel from "../../../services/mixpanelService";
import { emojiBlasts } from "emoji-blast";
import { routes } from "../../../routes/routes.constant";

const useGameBubblePop = () => {
  const [bubbles, setBubbles] = useState<Bubble[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [isGameComplete, setIsGameComplete] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [isServerError, setServerError] = useState<boolean>(false);
  const [instructionModal, setInstructionModal] = useState<boolean>(false);
  const [isTimerActive, setIsTimerActive] = useState<boolean>(false); // State to track if the timer is active
  const stashlink = getLocalStorage("stashlink") as Stashlink
  const brandsData = useRef(getLocalStorage("brandsData") as SampleEligibleBrandsData).current;
  const { playMoneySound, playLastBubbleSound, stopGameMusic, startGameMusic } = useGameSounds();
  const location = useLocation();
  const navigation = useHistory();
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    if (isTimerActive && !isGameComplete && !isModalOpen) {
      const timer = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);

      return () => clearInterval(timer);
    }
  }, [isTimerActive, isGameComplete, isModalOpen]);

  // Mutation hooks
  const [startCoinDrop, { data: startCoinDropData, error: startCoinDropError }] = useMutation<StartCoinDropData>(START_COIN_DROP, {
    variables: {
      campaignId: brandsData?.sampleEligibleBrands?.campaign.id,
      userIdentifier: getLocalStorage("userId") ? getLocalStorage("userId") : getLocalStorage("generatedUserId"),
      gameType: gameType.bubblePop,
      brandId: brandsData?.sampleEligibleBrands?.brand.id,
      web: true
    }
  });

  const [collectCoins, { data: collectCoinsData }] = useMutation<
    any,
    {
      coinDropId: number;
      coinsCollected: number;
      silverCoinsCollected: number;
      goldCoinsCollected: number;
      redCoinsCollected: number;
      purpleCoinsCollected: number;
      secret?: string;
      referrerUserId?: string;
      secondaryReferrerUserId?: string;
      completionTimeMs?: number;
      stashlinkId?: string;
    }
  >(COLLECT_COINS, {
    variables: {
      coinDropId: Number(startCoinDropData?.startCoinDrop?.coinDrop?.id),
      coinsCollected: 0,
      silverCoinsCollected: 100,
      goldCoinsCollected: 0,
      redCoinsCollected: 0,
      purpleCoinsCollected: 0,
      completionTimeMs: time,
      stashlinkId: stashlink?.id,
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    const unlisten = navigation.listen((location_, action) => {
      if (action === "POP" || action === "PUSH" || action === "REPLACE") {
        stopGameMusic();
      }
    });

    return () => {
      unlisten();
    };
  }, [navigation]);

  useEffect(() => {
    mixpanel.track("game-viewed");
    generateBubbles();
  }, []);

  useEffect(() => {
    const unlisten = navigation.listen((location_, action) => {
      if (action === "POP") {
        stopGameMusic();
      }
    });
    return () => {
      unlisten();
    };
  }, [navigation]);

  // handle mute when user minimize the browser
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Pause the music when the page is hidden
        stopGameMusic();
      } else {
        // Play the music when the page becomes visible again, considering mute status
        startGameMusic();
      }
    };

    // Add the event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  });
  const navigate = useHistory();

  useEffect(() => {
    if (isGameComplete) {
      const { cancel } = emojiBlasts({
        interval: 700,
        process(element) {
          element.className = "emoji-blast emoji-blast-game";
        }
      });
      const navigateTimer = setTimeout(() => {
        navigate.replace({ ...location, state: {} });
        navigate.replace(routes.campaignComplete, { fromValidFlow: true });
      }, timeout.threeThousand);

      return () => {
        clearTimeout(navigateTimer);
        cancel();
      };
    }
  }, [isGameComplete]);

  useEffect(() => {
    const state = location.state as { fromValidFlow: boolean };
    if (!state?.fromValidFlow || getLocalStorage("gamePlayCount") > 1) {
      const stashLink = getLocalStorage("stashLink");
      navigation.push(stashLink ? stashLink : "/");
    }
  }, [location.state]);

  useEffect(() => {
    scroll.lock();
    return () => {
      scroll.enable();
    };
  }, []);
  //Prevent swipe right and pinch zoom events
  useEffect(() => {
    const targetElement = document.querySelector("div");
    const gestureHandler = new GestureHandler(targetElement);

    gestureHandler.attachPreventPinchZoomListeners();
    gestureHandler.attachPreventSwipeRightListeners();
    if (isGameComplete) {
      gestureHandler.detachPreventPinchZoom();
      gestureHandler.detachPreventSwipeRightListeners();
    }

    return () => {
      gestureHandler.getCleanupFunction()();
    };
  }, [isGameComplete]);

  const generateBubbles = useCallback(() => {
    let remainingAmount = 1.0;
    const generatedValues: number[] = [];

    for (let i = 0; i < 39; i++) {
      let validValues = gameDefaultAmounts.filter((value) => value <= remainingAmount && remainingAmount - value >= 0.01 * (38 - i));

      if (validValues.length === 0) {
        validValues = [Math.min(...gameDefaultAmounts.filter((value) => value <= remainingAmount))];
      }

      const value = validValues[Math.floor(Math.random() * validValues.length)];
      generatedValues.push(value);
      remainingAmount = parseFloat((remainingAmount - value).toFixed(2));
    }

    generatedValues.push(parseFloat(remainingAmount.toFixed(2)));
    generatedValues.sort(() => Math.random() - 0.5);

    const bubbleArray = Array(49).fill({ value: null, revealed: false });
    let j = 0;
    for (let i = 0; i < 49; i++) {
      if (!emptyBubble.includes(i)) {
        bubbleArray[i] = { value: generatedValues[j], revealed: false };
        j++;
      }
    }

    setBubbles(bubbleArray);
  }, []);

  // Tracks game completion
  useEffect(() => {
    if (total.toFixed(2) === oneDollar) {
      const timeoutId = setTimeout(() => {
        mixpanel.track("game-finished");
        setIsGameComplete(true);
        stopGameMusic();
        collectCoins();
        setLocalStorage("gameCompleteTime", time);
      }, timeout.halfSecond);

      return () => clearTimeout(timeoutId);
    }
  }, [total]);

  // Reveals a bubble, updates total, plays sound, and starts timer if not started
  const revealBubble = useCallback(
    (index: number) => {
      if (bubbles[index].revealed || bubbles[index].value === null) return;

      if (!isTimerActive) setIsTimerActive(true); // Start the timer on first bubble tap

      setBubbles((prevBubbles) =>
        prevBubbles.map((bubble, i) => (i === index ? { ...bubble, beingRevealed: true, revealed: true } : bubble))
      );
      setTotal((prevTotal) => parseFloat((prevTotal + (bubbles[index].value as number)).toFixed(2)));

      const isLastBubble = bubbles.filter((b) => b.revealed).length === 39;

      if (isLastBubble) playLastBubbleSound();
      else playMoneySound();

      setTimeout(() => {
        setBubbles((prevBubbles) => prevBubbles.map((bubble, i) => (i === index ? { ...bubble, beingRevealed: false } : bubble)));
      }, 50);
    },
    [bubbles, playMoneySound, playLastBubbleSound, isTimerActive]
  );

  // Modal control and game start logic
  const closeModal = useCallback(() => {
    if (startCoinDropError?.message.includes("500")) {
      setServerError(true);
      setIsModalOpen(false);
      return;
    }

    setIsModalOpen(false);
    if (!instructionModal) {
      mixpanel.track("game-started");
      startCoinDrop();
      startGameMusic();
      setInstructionModal(true);
    }
  }, [instructionModal, startCoinDrop, startGameMusic, startCoinDropError]);

  const closeWaitListModal = () => {
    setServerError && setServerError(false);
    const stashLink = getLocalStorage("stashLink");
    navigation.push(stashLink ? stashLink : "/");
  };

  useEffect(() => {
    if (collectCoinsData) {
      setLocalStorage("coinDropId", collectCoinsData?.collectCoins?.coinDrop?.id);
      setLocalStorage("gamePlayCount", getLocalStorage("gamePlayCount") + 1);
    }
  }, [collectCoinsData]);

  return {
    bubbles,
    total,
    isGameComplete,
    isModalOpen,
    isServerError,
    revealBubble,
    closeModal,
    instructionModal,
    setInstructionModal,
    setIsModalOpen,
    time,
    closeWaitListModal
  };
};

export default useGameBubblePop;
