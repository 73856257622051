import React from "react";
import { TextAreaProps } from "./text-area.types";

const TextArea: React.FC<TextAreaProps> = ({
  name,
  maxLength,
  label,
  placeholder,
  value,
  onChange,
  error,
  containerClassName,
  inputClassName,
  currentLength,
  rows = 2
}) => (
  <div className={`pb-2 ${containerClassName} relative`}>
    {label && <label className="block text-sm font-normal text-placeholder-grey">{label}</label>}
    <div className="mt-1">
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        rows={rows}
        className={`text-sm !text-base-white font-medium placeholder-placeholder-grey placeholder-opacity-50 mt-1 block w-full px-3 py-2 placeholder:tracking-wider border bg-grey/5 ${error ? "border-red-500 border-2" : "border-grey-op70/70 focus:border-grey"} rounded-md shadow-sm focus:border-2 focus:outline-none sm:text-sm ${inputClassName}`}
      />
      {maxLength && (
        <div className={`text-xs ${error ? " text-error" : "text-grey"} mt-1 flex justify-end`}>
          {currentLength}/{maxLength}
        </div>
      )}
    </div>
  </div>
);

export default TextArea;
