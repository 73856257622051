import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { getCookie, getLocalStorage } from "../../../utils/common-function";
import useUser from "../../../modules/campaign/hooks/useUser";
import mixpanel from "../../../services/mixpanelService";
import { routes } from "../../../routes/routes.constant";
import { useErrorBoundary } from "react-error-boundary";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

export const useHeader = () => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState<boolean>(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(getLocalStorage("userId"));
  const [profileImage, setProfileImage] = useState("");
  const history = useHistory();
  const location = useLocation();
  const { userData, fetchUser } = useUser();
  const [isWaitlistModalOpen, setWaitlistModalOpen] = useState(false);
  const { resetBoundary } = useErrorBoundary();
  const onboardingFeatureFlag = useFeatureIsOn("onboarding-flow");

  const closeWaitListModal = () => {
    setWaitlistModalOpen && setWaitlistModalOpen(false);
  };

  // Sync userId state with localStorage continuously
  useEffect(() => {
    const delayTime: number = !userId ? 0 : 500;
    const syncUserId = () => {
      const storedUserId = getLocalStorage("userId");
      if (!userId) {
        setProfileImage("");
      }
      if (storedUserId !== userId) {
        setUserId(storedUserId);
      }
    };

    const intervalId = setInterval(syncUserId, delayTime);

    return () => clearInterval(intervalId);
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchUser({ variables: { userId } });
    }
  }, [fetchUser, userId]);

  useEffect(() => {
    if (userData?.user?.profilePhoto) {
      setProfileImage(userData.user.profilePhoto);
    }
  }, [userData?.user?.profilePhoto]);

  const toggleProfileMenu = useCallback(() => {
    setIsProfileMenuOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const onClickStashrunLogo = useCallback(() => {
    mixpanel.track("header-logo-clicked");
    if (!isUserOnAuthRoute) {
      //TODO: uncomment it after test all the scenatios
      //history.replace({ ...location, state: {} });
      resetBoundary();
      history.push("/");
    }
  }, []);

  const onClickUserIcon = useCallback(() => {
    mixpanel.track("header-user-profile-clicked");
    if (onboardingFeatureFlag) {
      const token = getCookie("token");
      if (!token) {
        history.push(routes.onboarding, { state: location.state, from: location.pathname });
      } else {
        toggleProfileMenu();
      }
    } else {
      setWaitlistModalOpen(true);
    }
  }, [isWaitlistModalOpen]);

  const isUserOnAuthRoute = useMemo((): boolean => {
    const currentRoute: string = history.location.pathname;
    return currentRoute.includes("onboarding") || currentRoute.includes("login") || currentRoute.includes("forgot-password");
  }, [history.location.pathname]);

  const toggleHamburgerMenu = useCallback(() => {
    mixpanel.track("header-hamburger-clicked");
    setIsHamburgerOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const closeHamburgerMenu = useCallback(() => setIsHamburgerOpen(false), []);

  const onClickJoinWaitlist = useCallback(() => {
    closeHamburgerMenu();
    setWaitlistModalOpen(true);
  }, []);

  return {
    isHamburgerOpen,
    onClickStashrunLogo,
    isProfileMenuOpen,
    profileImage,
    onClickUserIcon,
    isUserOnAuthRoute,
    toggleHamburgerMenu,
    toggleProfileMenu,
    isWaitlistModalOpen,
    closeWaitListModal,
    closeHamburgerMenu,
    onClickJoinWaitlist,
    closeProfileMenu: () => setIsProfileMenuOpen(false)
  };
};
