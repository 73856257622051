import { IoIosArrowBack } from "react-icons/io";
import React, { useEffect, useRef, useState } from "react";
import ImageContainer from "../../components/common/image-conatiner/image-container";
import SocialMediaButtons from "../../components/common/social-media-buttons/social-media-buttons";
import Button from "../../components/common/button";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../routes/routes.constant";
import DetailsTable from "../../components/detailsTable";
import { generateCloudinaryImageUrl, generateCloudinaryVideoUrl, getLocalStorage } from "../../utils/common-function";
import { SampleEligibleBrandsData } from "../../types";
import VolumeMute from "./../../assets/images/Mute.svg";
import VolumeFull from "./../../assets/images/volume-full.svg";
import mixpanel from "../../services/mixpanelService";

const SponsorDetails: React.FC = () => {
  const navigation = useHistory();
  const location = useLocation();
  const brand = (getLocalStorage("brandsData") as SampleEligibleBrandsData).sampleEligibleBrands?.brand;
  const stashlink = getLocalStorage("stashlink");
  const campaign = stashlink.campaign;
  const [muted, setMuted] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const state = location.state as { fromValidFlow: boolean };
    if (!state?.fromValidFlow) {
      const stashLink = getLocalStorage("stashLink");
      navigation.push(stashLink ? stashLink : "/");
    }
  }, [location.state]);

  const handleVideoPlayback = () => {
    if (document.hidden) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVideoPlayback);
    mixpanel.track("brand-profile-viewed", { brandName: brand?.name });

    return () => {
      document.removeEventListener("visibilitychange", handleVideoPlayback);
    };
  }, []);

  const handleMuteToggle = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    event.stopPropagation();
    setMuted((prevMuted) => {
      mixpanel.track("sound-clicked", { location: "brand-profile-page", muted: !prevMuted, brandName: brand?.name });
      if (prevMuted && videoRef.current) {
        videoRef.current.muted = false;
        videoRef.current.play();
      } else if (videoRef.current) {
        videoRef.current.muted = true;
      }
      return !prevMuted;
    });
  };

  return (
    <div className="min-h-screen flex flex-col bg-soft-black text-white">
      <div className="flex-grow pb-8">
        <div className="flex w-screen justify-center bg-soft-black ">
          <div className="w-11/12 gap-8 text-white bg-soft-black rounded-lg">
            <div className="w-full h-12 flex items-center relative">
              <IoIosArrowBack className="text-gray-400 h-6 w-6 absolute left-0 top-3" onClick={() => navigation.goBack()} />

              <h3 className="flex-1 text-center">Meet your game sponsor</h3>
            </div>
            <h1 className="-mt-1 flex-1 text-center text-28px mb-6 text-[#f8f7f6] font-extrabold leading-[29.54px]">
              {brand?.name}
            </h1>
          </div>
        </div>
        <div className="bg-primary-l1">
          <div className="bg-black/75 flex flex-row justify-between items-center gap-5 px-4">
            <div
              onClick={() => mixpanel.track("brand-logo-clicked", {
                brandName: brand?.name,
              })}
              className="flex justify-center items-center rounded-full w-c-100 h-c-100 bg-base-white overflow-hidden relative -top-3">
              <ImageContainer
                src={generateCloudinaryImageUrl(brand?.imageUrl || "", "176", "176")}
                className="w-c-92 h-c-92 object-cover rounded-full border-2 border-soft-black"
              />
            </div>
            <p className="w-c-100-146 font-secondary text-base text-base-white font-normal leading-tight-20.8">
              <span className="font-bold text-primary-l1">They&rsquo;ll donate 100% of the money</span> you collect while playing. Start
              stashing!
            </p>
          </div>
        </div>
        {brand?.videoUrl ? (
          <div className="relative w-full">
            <video
              playsInline
              ref={videoRef}
              src={generateCloudinaryVideoUrl(brand?.videoUrl ?? "", "480", "365", 'c_pad,b_blurred:400:15')}
              className="w-full object-cover border-t-2 border-b-2 border-soft-black"
              muted={muted}
              autoPlay
              loop
            />

            <div className="absolute top-2 right-2 h-8 w-8 rounded-full flex justify-center items-center p-2 bg-black/30 backdrop-filter-blur">
              <img src={muted ? VolumeMute : VolumeFull} alt={VolumeFull} onClick={handleMuteToggle} />
            </div>
          </div>
        ) : (
          <img
            key={brand?.imageUrl}
            src={generateCloudinaryImageUrl(brand?.heroImageUrl || "", "480", "365")}
            alt="brand images"
            className="w-full aspect-video object-cover border-t-2 border-b-2 border-soft-black"
          />
        )}

        <div className="flex flex-row gap-px overflow-x-auto whitespace-nowrap">
          {brand?.brandImages
            ?.slice(0, 10)
            .map((image, id) => (
              <ImageContainer
                key={id}
                transformation="c_auto,h_120,w_120"
                src={image.imageUrl}
                alt="brand images"
                className="w-full h-[72px] object-cover border-r border-soft-black"
              />
            )) || null}
        </div>
        <div className="flex flex-col gap-6 p-5">
          <p className="font-secondary text-neutral-50 text-sm text-base-white/90 font-normal leading-tight-18.2">{brand?.heroTagline}</p>
          <div className="flex flex-col gap-2 bg-grey-bg p-4 pt-3 rounded-2xl">
            <h1 className="font-bold font-primary text-22px text-base-white">Our Story</h1>
            {brand?.description && (
              <p
                className="font-secondary text-base-white/80 text-sm font-normal leading-[18.2px] "
                dangerouslySetInnerHTML={{
                  __html: brand?.description.replaceAll("\r\n", "<br/>")
                }}></p>
            )}
          </div>
          {brand?.website && (
            <div
              onClick={() => mixpanel.track("brand-website-clicked", {
                brandName: brand?.name,
              })}
              className="w-[108px] h-10 flex justify-center items-center border border-base-white/80 rounded-lg">
              <a
                href={brand?.website.startsWith("http") ? brand.website : `https://${brand?.website}`}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-transparent text-center text-sm text-base-white/80 font-primary font-semibold">
                Website
              </a>
            </div>
          )}

          <div className="flex flex-row gap-4">
            <SocialMediaButtons
              screen="brand"
              facebookUsername={brand?.facebookUsername}
              instagramUsername={brand?.instagramUsername}
              twitterUsername={brand?.twitterUsername}
              youtubeUsername={brand?.youtubeUsername}
              tiktokUsername={brand?.tiktokUsername}
              linkedinUrl={brand?.linkedinUrl}
            />
          </div>
        </div>

        <DetailsTable
          items={[
            { label: "Registered name", value: brand?.name || "" },
            // { label: "Joined", value: convertDateToMonthYear(brand?.joinedAt) },
            { label: "Headquarters", value: brand?.location || "" },
            { label: "Founded in", value: brand?.yearFounded || "" }
          ]}
        />
        {campaign?.cause?.name && (
          <div className="p-4 pb-20">
            <span
              onClick={() => mixpanel.track("cause-hashtag-clicked", {
                brandName: brand?.name,
                campaignName: campaign?.title,
                causeName: campaign?.cause?.name,
              })}
              className="border border-base-white/10 text-base-white/80 text-sm leading-tight-16.8 font-primary font-medium bg-grey/10 px-3 py-1 rounded-2xl text-center inline-block">
              #{campaign?.cause?.name}
            </span>
          </div>
        )}
      </div>
      <div className="fixed bottom-0 w-full flex p-3 px-4 items-center justify-center bg-black/70 backdrop-blur-8">
        <div className="w-full">
          <Button
            title="Let's go!"
            isDisabled={false}
            onClick={() => {
              mixpanel.track("brand-play-game-button-clicked", {
                brandName: brand?.name,
                campaignName: campaign?.title,
              });
              navigation.replace({ ...location, state: {} });
              navigation.replace(routes.bubblePop, { fromValidFlow: true });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SponsorDetails;
