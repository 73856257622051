import React, { useState, useEffect, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShareButton from "../common/shareButton/shareButton";
import ShareQRCode from "../common/shareQRCode/shareQRCode";
import { ShareModalProps } from "./shareModal.types";
import { sendSMS } from "../../utils/share";
import StashrunLogoBeta from "./../../assets/images/Stashrun-beta-logo-grey.png";
import { formatCurrencyToUsd } from "../../utils/format";
import useShareModal from "./hooks/useShareModal";
import VerifiedIcon from "./../../assets/images/VerifiedIcon.svg";
import Check from "./../../assets/images/Check.svg";
import { convertHttpToHttps, convertToPercentage, generateCloudinaryImageUrl, getLocalStorage, splitName } from "../../utils/common-function";
import { RxCross2 } from "react-icons/rx";
import mixpanel from "../../services/mixpanelService";
import Loader from "../common/loader/loader";
import { SHARE_STASHLINK } from "../../queries";
import { useMutation } from "@apollo/client";
import { Stashlink } from "../../types";

const ShareComponent: React.FC<ShareModalProps> = (props: ShareModalProps) => {
  const { imageRef, imgSrc } = useShareModal(props);
  const [showQr, setShowQr] = useState<boolean>(false);
  const [isCopyLinkDisabled, setIsCopyLinkDisabled] = useState<boolean>(false);
  const [longPress, setLongPress] = useState<boolean>(false);
  const [pressTimer, setPressTimer] = useState<NodeJS.Timeout | null>(null);
  const percentageCompleted = convertToPercentage(props?.currentAmount, props?.goalAmount);
  const [shareStashlink] = useMutation(SHARE_STASHLINK);
  const stashlink = getLocalStorage("stashlink") as Stashlink;
  const { firstName, lastName } = splitName(props.stashUser?.fullName || "");
  const { firstName: colinkUserFirstName, lastName: colinkUserLastName } = splitName(props.coLinkUser?.fullName || "");

  const handleLongPress = useCallback(() => {
    mixpanel.track("long-press-detected", {
      charityName: props.charityName,
      shareUrl: props.shareUrl,
    });
    shareStashlink({
      variables: {
        stashlinkId: stashlink?.id
      }
    });
  }, []);

  const startPressTimer = useCallback(() => {
    setPressTimer(
      setTimeout(() => {
        setLongPress(true);
      }, 800) // 800ms for long press
    );
  }, []);

  const clearPressTimer = useCallback(() => {
    if (pressTimer) {
      clearTimeout(pressTimer);
      setPressTimer(null);
    }
    setLongPress(false);
  }, [pressTimer]);

  useEffect(() => {
    if (longPress) {
      handleLongPress();
    }
  }, [longPress, handleLongPress]);

  const handleShowQRCode = () => {
    toast.dismiss(); // Dismiss any active toast
    setTimeout(() => {
      setShowQr(true); // Delay the QR code modal to ensure toast is dismissed first
    }, 200);
  };

  const copyLink = (shareUrl: string) => {
    mixpanel.track("copy-link-button-clicked", {
      charityName: props.charityName,
      shareUrl,
    });
    setIsCopyLinkDisabled(true);
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        toast.success(
          <div className="w-full flex flex-col items-center">
            <img src={Check} alt="Success" className="w-22 h-22" />
            <span className="font-proxima-nova text-[#B0C1D3] text-base font-semibold leading-5 text-center">Copied</span>
          </div>,
          {
            icon: false,
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            closeButton: false,
            onClose: () => setIsCopyLinkDisabled(false)
          }
        );
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
        setIsCopyLinkDisabled(false);
      });
  };

  useEffect(() => {
    if (props.isOpen) {
      document.body.classList.add('prevent-scroll');
    } else {
      document.body.classList.remove('prevent-scroll');
    }
    return () => {
      document.body.classList.remove('prevent-scroll');
    };
  }, [props.isOpen]);

  return (
    <div>
      <div
        className={`fixed inset-0 z-50 flex flex-col justify-start bg-soft-black text-white rounded-t-2xl transition-transform duration-300 ${
          props.isOpen ? "translate-y-0" : "translate-y-full"
        }`}>
        <header className="text-center p-4 pb-0 flex flex-col">
          <div className="flex flex-col justify-center items-center">
            <p className="text-xl font-bold font-primary text-base-white leading-normal">Share to raise more</p>
            <p className="text-sm text-base-white font-secondary mt-1.5 xxsh:mt-0 mb-0 inter-I bg-grey/20 px-4 py-1 rounded-3xl w-fit">
              ✨ Long press image to save and<br></br>post with the link to your IG 👇
            </p>
          </div>
          <button onClick={props.onClose} className="absolute right-4 text-grey text-lg hover:text-gray-100 font-semibold z-10">
            <RxCross2 />
          </button>
        </header>
        <div className="flex flex-col h-full justify-between xxsh:justify-end">
          <main className="h-full p-12 pt-4 pb-0 relative -bottom-0.5" style={{ maxHeight: "60vh" }}>
            {imgSrc ? (
              <div
                className="overflow-hidden border-2 border-r-8 border-black xxsh:border-b-0 rounded-3xl xxsh:rounded-b-none relative"
                style={{ border: "4px dashed rgb(255 255 255 / 40%)" }}>
                <div className="flex items-end w-full">
                  <img
                    src={imgSrc}
                    alt="User Stash Link"
                    onMouseDown={startPressTimer}
                    onMouseUp={clearPressTimer}
                    onMouseLeave={clearPressTimer}
                    onTouchStart={startPressTimer}
                    onTouchEnd={clearPressTimer}
                  />
                </div>
              </div>
            ) : (
              <div className="h-full flex flex-col items-center justify-start p-12 top-20">
                <Loader />
              </div>
            )}
          </main>
          <footer className="fixed bottom-0 w-full bg-primary-l1 border-modal-top-bg z-10">
            <div className="bg-black/90 grid grid-cols-3 gap-4 w-full py-4 xxsh:py-2 px-8">
              <ShareButton buttonName="SMS" buttonOnClick={() => {
                sendSMS(props.shareUrl ?? "https://app.stashrun.com", props.charityName ?? "a great charity")
                shareStashlink({
                  variables: {
                    stashlinkId: stashlink?.id
                  }
                });
              }} />
              <ShareButton buttonName="Copy Link" buttonOnClick={() => {
                copyLink(props?.shareUrl || "")
                shareStashlink({
                  variables: {
                    stashlinkId: stashlink?.id
                  }
                });
              }} disabled={isCopyLinkDisabled} />
              <ShareButton
                buttonName="QR Code"
                buttonOnClick={() => {
                  handleShowQRCode();
                  shareStashlink({
                    variables: {
                      stashlinkId: stashlink?.id
                    }
                  });
                  mixpanel.track("qr-button-clicked", {
                    charityName: props.charityName,
                    shareUrl: props.shareUrl,
                  });
                }}
              />
            </div>
          </footer>
        </div>
        <div className="absolute top-[-2000px] left-[-1200px]">
          <div ref={imageRef}>
            <div className="share-modal-bg h-[480px] px-c-50 pt-12 pb-32 w-[270px]">
              <div className="bg-soft-black rounded-2xl overflow-hidden border-r-2.44 border-b-2.44 border-primary-l1">
                <div className="bg-black/30">
                  <div className="bg-soft-black rounded-[12px] p-2 pb-1">
                    <div className="relative overflow-hidden mb-0.5">
                      {props.coLinkUser ? (
                        <div className="flex w-full aspect-video">
                          <div className="rounded-l-[8px] w-1/2 h-full border-r-2 border-soft-black flex items-center justify-center">
                            <img
                              src={generateCloudinaryImageUrl(convertHttpToHttps(props.stashUser.profilePhoto) || "", "320", "360")}
                              alt="Stash User"
                              className="w-full h-full object-cover rounded-l-[8px]"
                            />
                          </div>
                          <div className="rounded-r-[8px] w-1/2 h-full border-l-2 border-soft-black flex items-center justify-center">
                            <img
                              src={generateCloudinaryImageUrl(convertHttpToHttps(props.coLinkUser.profilePhoto) || "", "320", "360")}
                              alt="Co-link User"
                              className="w-full h-full object-cover rounded-r-[8px]"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="rounded-[8px] w-full aspect-video flex items-center justify-center">
                          <img
                            src={generateCloudinaryImageUrl(convertHttpToHttps(props.stashUser.profilePhoto) || "", "960", "540")}
                            alt="Stash User"
                            className="w-full h-full object-cover rounded-[8px] c-object-fit"
                          />
                        </div>
                      )}
                      <img
                        src={
                          props.coLinkUser
                            ? generateCloudinaryImageUrl(convertHttpToHttps(props.charityLogo) || "", "89", "89")
                            : generateCloudinaryImageUrl(convertHttpToHttps(props.charityLogo) || "", "120", "120")
                        }
                        alt="Logo"
                        className={`absolute -bottom-1 ${
                          props.coLinkUser ? "left-2/4 -translate-x-2/4" : "-left-1"
                        } w-11 h-11 rounded-full border-4 border-soft-black`}
                        crossOrigin="anonymous"
                      />
                    </div>

                    <div className="text-center relative -top-2">
                      {props.coLinkUser ? (
                        <>
                          <p className="text-base font-extrabold font-secondary text-primary-l1 inline-block m-0 leading-tight">
                            {firstName}{" "}
                            <span className="inline-block">
                              {lastName}
                              {props.stashUser?.isPublicProfile && (
                                <img
                                  src={VerifiedIcon}
                                  alt={VerifiedIcon}
                                  className="ml-0.5 w-8.98 h-8.98 inline-block align-middle relative top-1"
                                />
                              )}
                            </span>
                          </p>
                          <p className="text-primary-l1 text-xs font-primary font-extrabold m-0 -mt-1.5 -mb-1.5">x</p>
                          <p className="text-base font-extrabold font-secondary text-primary-l1 inline-block m-0 leading-tight">
                            {colinkUserFirstName}{" "}
                            <span className="inline-block">
                              {colinkUserLastName}
                              {props.coLinkUser?.isPublicProfile && (
                                <img
                                  src={VerifiedIcon}
                                  alt={VerifiedIcon}
                                  className="ml-0.5 w-8.98 h-8.98 inline-block align-middle relative top-1"
                                />
                              )}
                            </span>
                          </p>
                        </>
                      ) : (
                        <p className="text-base font-extrabold font-secondary text-primary-l1 inline-block">
                          {firstName}{" "}
                          <span className="inline-block">
                            {lastName}
                            {props.stashUser?.isPublicProfile && (
                              <img
                                src={VerifiedIcon}
                                alt={VerifiedIcon}
                                className="ml-0.5 w-8.98 h-8.98 inline-block align-middle relative top-1"
                              />
                            )}
                          </span>
                        </p>
                      )}
                      <p className="text-10.48 text-base-white font-secondary font-normal">is stashing for</p>
                      <p className="text-sm text-base-white font-bold font-secondary">{props.charityName}</p>
                      <div className="mt-4 text-white">
                        <div className="relative w-full h-2 bg-grey-bg rounded-xl overflow-hidden mb-1 top-1">
                          <div className="absolute h-full bg-primary-l1" style={{ width: `${percentageCompleted}%` }}></div>
                        </div>
                        <p className="-mt-2">
                          <span className="text-[0.686rem] font-bold font-secondary leading-[0.823rem]">
                            {formatCurrencyToUsd(props.currentAmount)}{" "}
                          </span>
                          <span className="text-8.98 text-neutral-50 font-secondary font-normal leading-3">
                            raised of {formatCurrencyToUsd(props.goalAmount)}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="px-2 py-3 flex justify-center items-center">
                    <img src={StashrunLogoBeta} alt={StashrunLogoBeta} className="h-2" />
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute left-2/4 -translate-x-2/4 bottom-c-104 w-[calc(100vh+10px)] bg-primary-l1 text-black text-center text-sm leading-none font-bold tracking-wide font-primary py-2 -rotate-3">
              <span className="relative -top-2">👇 Play to fund 👇 Play to fund 👇 Play to fund 👇</span>
            </div>
          </div>
        </div>

        {showQr && (
          <>
            {props.coLinkUser ? (
              <ShareQRCode
                stashUser={props.stashUser}
                coLinkUser={props.coLinkUser}
                charityName={props.charityName}
                charityLogo={props.charityLogo}
                shareUrl={props.shareUrl}
                isOpen={showQr}
                onClose={() => setShowQr(false)}
              />
            ) : (
              <ShareQRCode
                stashUser={props.stashUser}
                charityName={props.charityName}
                charityLogo={props.charityLogo}
                shareUrl={props.shareUrl}
                isOpen={showQr}
                onClose={() => setShowQr(false)}
              />
            )}
          </>
        )}
      </div>
      <ToastContainer
        className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50 w-[10.5rem] h-[10.5rem] top-[17.65625rem] -mt-16"
        toastClassName={() =>
          "w-full h-full bg-copied-toaster-bg/30 text-white flex items-center justify-center rounded-[20px] backdrop-filter-blur"
        }
      />
    </div>
  );
};
export default ShareComponent;
