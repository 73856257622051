import React, { useState } from "react";
import { FiX } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import TextArea from "../common/text-area/text-area";
import { ColinkModalProps } from "./colinkModal.types";
import Edit from "./../../assets/images/edit.svg";
import CameraVideo from "./../../assets/images/camera-video-plus-add.svg";
import ColinkGreyModal from "../common/colinkModal/colinkModal";
import { fileSizeLimitInMB } from "../../utils/constants";
import mixpanel from "../../services/mixpanelService";
import { ToastContainer } from "react-toastify";
import { showErrorToast } from "../../utils/common-function";

const ColinkModal: React.FC<ColinkModalProps> = (props: ColinkModalProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [thankyouMessage, setThankyouMessage] = useState<string>("");
  const [showUploadOptions, setShowUploadOptions] = useState<boolean>(false);
  const history = useHistory();
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    navigateFunction(file, null);
  };

  const onModalClose = () => {
    setShowModal(false);
    setThankyouMessage("");
  };

  const navigateFunction = (file: Blob | File | undefined | null, thankyouMessage: string | null) => {
    if (file) {
      const maxSizeInBytes = fileSizeLimitInMB * 1024 * 1024; // Convert MB to bytes
      if (file.size > maxSizeInBytes) {
        showErrorToast(`File size exceeds ${fileSizeLimitInMB} MB. Please select a smaller file.`);
        setShowUploadOptions(false);
        return;
      }
    }
    history.push("/create-stashlink", { file: file, thankyouMessage: thankyouMessage, isCreateStashlink: props.isCreateStashlink });
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black-op80/80 backdrop-blur-5">
      <div className="relative h-full w-full">
        <div className="absolute top-full -translate-y-full bg-black/60 backdrop-blur-13.4 px-4 pt-7 pb-6 rounded-xl w-full relative">
          <button onClick={props.onClose} className="absolute top-4 right-4 text-grey">
            <FiX size={24} />
          </button>

          <h2 className="text-base-white text-xl leading-tight-24.48 font-primary font-semibold  mb-3 px-12 text-center">
            Personalize your link and share
          </h2>

          <p className="text-white text-base inter-I font-normal text-center font-secondary mb-28 px-8">
            Include a short video or thank you message to inspire more support. <span className="text-red-500">❤️</span>
          </p>

          <div
            className="relative flex items-center justify-center bg-grey/15 border border-grey rounded-lg text-base-white font-primary font-bold mt-2 mb-4 p-11 cursor-pointer"
            onClick={() => {
              mixpanel.track("write-message-clicked");
              setShowModal(true);
            }}>
            <span>Write a message</span>
            <img src={Edit} alt={Edit} className="absolute top-2/4 -translate-y-2/4 right-4" />
          </div>
          <div className="relative">
            <button
              onClick={() => {
                mixpanel.track("upload-video-clicked");
                setShowUploadOptions(!showUploadOptions);
              }}
              className="w-full relative flex items-center justify-center bg-primary-l1 border border-primary-l1 rounded-lg text-black font-primary font-wght-740 mb-2 p-11 cursor-pointer">
              <span>Upload video</span>
              <img src={CameraVideo} alt={CameraVideo} className="absolute top-2/4 -translate-y-2/4 right-4" />
            </button>

            {showUploadOptions && (
              <div className="absolute bottom-14 right-0 mt-1 bg-black-op25 rounded-13 shadow-lg w-3/4 z-10">
                <label className="relative block text-left px-4 py-2 text-base-white/80 border-b border-base-white/20 rounded-t-13 cursor-pointer">
                  <span>Photo Library</span>
                  <input type="file" accept="video/*" className="hidden" onChange={handleFileChange} />
                </label>
                <input
                  type="file"
                  accept="video/*"
                  capture="environment"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="videoInput"
                />
                <label
                  htmlFor="videoInput"
                  className="relative block text-left px-4 py-2 text-base-white/80 border-b border-base-white/20 rounded-t-13 cursor-pointer">
                  <span>Take a Video</span>
                </label>
              </div>
            )}
          </div>

          <p className="text-grey text-sm text-center italic mt-1">Up to 1 minute, shot vertically or square</p>
        </div>
      </div>

      {showModal && (
        <ColinkGreyModal
          isOpen={showModal}
          title="Write a message"
          onButtonClick={() => navigateFunction(null, thankyouMessage)}
          buttonTitle="Done"
          onClickClose={onModalClose}
          isDisabled={thankyouMessage.length === 0}>
          <TextArea
            label="Thank you message"
            name="thankyouMessage"
            placeholder="Add message"
            value={thankyouMessage}
            onChange={(e) => setThankyouMessage(e.target.value)}
            maxLength={140}
            currentLength={thankyouMessage.length}
            rows={4}
          />
        </ColinkGreyModal>
      )}
      <ToastContainer />
    </div>
  );
};

export default ColinkModal;
